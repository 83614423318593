import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserRole } from '@entities/users/user-role.enum';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Observable } from 'rxjs';

export class RoleGuard {
  public static forRoles(...roles: UserRole[]) {
    @Injectable({
      providedIn: 'root',
    })
    class RoleCheck {
      public constructor(private authService: AuthenticationService, private router: Router) {}
      public canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.roleHasAccessToRoute()) {
          return true;
        } else {
          const defaultRoute = this.authService.getDefaultRoute();
          this.router.navigate(defaultRoute);
          return false;
        }
      }

      public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.roleHasAccessToRoute()) {
          return true;
        } else {
          const defaultRoute = this.authService.getDefaultRoute();
          this.router.navigate(defaultRoute);
          return false;
        }
      }

      private roleHasAccessToRoute() {
        const userToken = this.authService.getDecodedToken();
        return roles.includes(userToken.roleId);
      }
    }

    return RoleCheck;
  }
}
